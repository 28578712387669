.footer-area  img{
    width: auto;
    max-width: 100%;
}

.footer-area .social-icons a{
    height: 45px;
    width: 45px;
    line-height: 45px;
    border: 1px solid #777;
    display: inline-block;
}

.footer-area .social-icons a:hover{
    border: 1px solid #df1d23;
}

/**** footer-area-2 ****/
.footer-area-2  img{
    width: auto;
    max-width: 100%;
}

.footer-area-2 .social-icons a{
    height: 35px;
    width: 35px;
    line-height: 35px;
    border: 1px solid #aaa;
    display: inline-block;
}

.footer-area-2 .social-icons a:hover{
    border: 1px solid #df1d23;
}