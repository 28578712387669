.welcome-slider .container-fluid {
  padding: 0;
}

.welcome-area {
  /* background-image: url(../images/k-95-ake-367f-l_2-id-55372-jpeg.jpg); */
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.welcome-area .overlay-bg-70 {
  height: 100vh;
}

.welcome-area .welcome-text p {
  margin: 0 22%;
}

.welcome-area.text-left .welcome-text p {
  margin: 0 45% 20px 0;
}
.welcome-slider,
.welcome-slider .flex-center {
  height: 100vh;
}

.welcome-slider .item .flex-center h1,
.welcome-slider .item .flex-center h4,
.welcome-slider .item .flex-center p {
  opacity: 0;
}

.welcome-slider p {
  margin: 0 22%;
}

/* .welcome-slider .owl-dots{
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
} */

.welcome-slider .owl-nav {
  position: absolute;
  bottom: 50%;
  width: 100%;
}

.welcome-slider .owl-nav .owl-prev {
  position: absolute;
  left: 20px;
  font-size: 30px;
  width: 50px;
  height: 50px;
  line-height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
}

.welcome-slider .owl-nav .owl-next {
  position: absolute;
  right: 10px;
  font-size: 30px;
  width: 50px;
  height: 50px;
  line-height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
}

.welcome-slider .owl-nav .owl-prev:hover,
.welcome-slider .owl-nav .owl-next:hover {
  background-color: #df1d23;
}
.imageSlide1 {
  background-image: url("../assets/welcomeSlider/Passenger.jpg");
  position: absolute;
  top: 0;
  left: 0;
  z-index: -999;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  /* background-image: url("../assets//welcomeSlider/Commercial.jpg"); */
}

.slider {
  overflow: hidden;
  /* width: 100vw;
 height: 100vh;
 position: relative; */
}

.slider .slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -999;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  animation: slider 50.5s infinite;
}

@keyframes slider {
  0%,
  15%,
  100% {
    transform: translateX(0);
    animation-timing-function: ease;
  }
  20% {
    transform: translateX(-100%);
    animation-timing-function: step-end;
  }
  95% {
    transform: translateX(100%);
    animation-timing-function: ease;
  }
}

.slider .slide:nth-child(1) {
  background-image: url("../assets/welcomeSlider/Passenger.jpg");
  animation-delay: 0;
}

.slider .slide:nth-child(2) {
  background-image: url("../assets/welcomeSlider/Earth_moving.jpg");
  animation-delay: 4s;
}

.slider .slide:nth-child(3) {
  background-image: url("../assets/welcomeSlider/Special.jpg");
  animation-delay: 8s;
}

.slider .slide:nth-child(4) {
  background-image: url("../assets/welcomeSlider/Air.jpg");
  animation-delay: 12s;
}

.slider .slide:nth-child(5) {
  background-image: url("../assets/welcomeSlider/Military.jpg");
  animation-delay: 16s;
}
.slider .slide:nth-child(6) {
  background-image: url("../assets/welcomeSlider/Forestry.jpg");
  animation-delay: 20s;
}
.slider .slide:nth-child(7) {
  background-image: url("../assets/welcomeSlider/Commercial.jpg");
  animation-delay: 24s;
}
.slider .slide:nth-child(8) {
  background-image: url("../assets/welcomeSlider/Mining.jpg");
  animation-delay: 28s;
}
.slider .slide:nth-child(9) {
  background-image: url("../assets/welcomeSlider/ATV.jpg");
  animation-delay: 32s;
}
.slider .slide:nth-child(10) {
  background-image: url("../assets/welcomeSlider/Warehousing.jpg");
  animation-delay: 36s;
}
.slider .slide:nth-child(11) {
  background-image: url("../assets/welcomeSlider/Agri.jpg");
  animation-delay: 40s;
}
.slider .slide:nth-child(12) {
  background-image: url("../assets/welcomeSlider/Passenger.jpg");
  animation-delay: 44;
}

/*The from section*/

/* Style inputs */
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #df1d23;
  color: white;
  width: 100%;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #df1d23;
}

/* Style the container/contact section */

/* Create two columns that float next to eachother */
.column {
  float: left;
  width: 50%;
  margin-top: 6px;
  padding: 20px;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 14px;
  margin-left: 2px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 6px;
    margin-left: 2px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
  .typeText {
    font-size: x-large;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 375px) {
  .slider {
    overflow: hidden;
    /* width: 100vw;
   height: 100vh;
   position: relative; */
  }
  .slider .slide {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -999;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    animation: slider 0s infinite;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .slider {
    overflow: hidden;
    /* width: 100vw;
   height: 100vh;
   position: relative; */
  }
  .slider .slide {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -999;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    animation: slider 0s infinite;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .MoveAbit {
    left: 10px;
    position: relative;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .MoveAbit {
    left: 10px;
    position: relative;
  }
}
