/*----------------------------------------------------
                     02-load-wrapp
 ----------------------------------------------------*/
 .load-wrapp {
    position: fixed;
    width: 100%;
    height: 100%;
    background:#fff;
    color:#fff;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index:99999;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.load-wrapp .wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%)translateY(-50%);
}

.load-wrapp .wrap ul.dots-box {
  position: relative;
  width: 80px;
  height: 80px;
  list-style: none;
}

.load-wrapp .wrap ul.dots-box li.dot {
  width: 100%;
  height: 100%;
  border-radius: 52px;
  top: 0;
  left: 0;
  z-index: 99;
  text-indent: -9999px;
  display: block;
  position: absolute;
  border: none;
}
.load-wrapp .wrap ul.dots-box li.dot span {
  background: #df1d23;
  bottom: 0px;
  left: 50%;
  margin-left: -2px;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.load-wrapp .wrap ul.dots-box li.dot {
  animation-iteration-count:infinite;
  animation-timing-function:linear;
  animation-name:orbit;
  animation-duration:4s;
}

.load-wrapp .wrap ul.dots-box li:nth-child(2) {
  animation-delay:0.2s;
}

.load-wrapp .wrap ul.dots-box li:nth-child(3) {
  animation-delay:0.4s;
}

.load-wrapp .wrap ul.dots-box li:nth-child(4) {
  animation-delay:0.6s;
}

.load-wrapp .wrap ul.dots-box li:nth-child(5) {
  animation-delay:0.8s;
}

@keyframes orbit {
  0% {
    transform:rotate(0deg);
    opacity: 1;
  }
  5% {
    transform:rotate(90deg);
    opacity: 1;
  }
  45% {
    transform:rotate(270deg);
    opacity: 1;
  }
  55% {
    transform:rotate(540deg);
    opacity: 1;
  }
  75% {
    transform:rotate(630deg);
    opacity: 1;
  }
  80% {
    transform:rotate(720deg);
    opacity: 0;
  }
  100% {
    transform:rotate(720deg);
    opacity: 0;
  }
}
/*----------------------------------------------------
                    End load-wrapp
----------------------------------------------------*/

/*----------------------------------------------------
                    03-intro-sec
----------------------------------------------------*/