.ModalIcon {
  width: 190px;
  height: 90px;
}
.ModalSize {
  max-width: 500px;
  max-width: 500px; /* Occupy the 90% of the screen width */
  height: 500px;
}
.ModalSizeDatahub {
  max-width: 500px; /* Occupy the 90% of the screen width */
  height: 500px;
}

.formModalDatahub {
  left: 39em;
  bottom: 20em;
  position: relative;
}
.formModalOem {
  left: 39em;
  bottom: 20em;
  position: relative;
}
.formModal {
  left: 39em;
  bottom: 0em;
  position: relative;
}
.formModalSales {
  left: 39em;
  bottom: 21em;
  position: relative;
}
.formModalFleet {
  left: 39em;
  bottom: 10em;
  position: relative;
}
.custom-modal {
  max-width: 70em;
}

.SolutionIcon:hover {
  color: #df1d23;
  cursor: pointer;
}
.cardSlid {
  cursor: pointer;
}

.Solution-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 720px) {
  .ModalIcon {
    width: 100px;
    height: 50px;
  }
  .formModal {
    left: 0em;
    bottom: 0em;
    position: relative;
  }

  .formModalDatahub {
    left: 0em;
    bottom: 2em;
    position: relative;
  }
  .formModalOem {
    left: 0em;
    bottom: 20em;
    position: relative;
  }
  .formModalSales {
    left: 0em;
    top: -3em;
    position: relative;
  }
  .DisplayNoneMobile {
    display: none;
  }
  .formModalFleet {
    left: 0em;
    top: -1em;
    position: relative;
  }
  .custom-modal {
    margin-top: 50px;
  }

  .SolutionIcon:hover {
    color: #df1d23;
    cursor: pointer;
  }
}

@media only screen and (max-width: 720px) {
  .ModalIcon {
    width: 100px;
    height: 50px;
  }
  .formModal {
    left: 0em;
    bottom: 0em;
    position: relative;
  }

  .carousel-control-prev {
    position: absolute;
    left: -1em;
  
  }
  
  .carousel-control-next {
    position: absolute;
    right: -1em;
  }

  .carousel-control-prev-icon {
    background-color: darkgray;
    position: absolute;
    left: 0em;
  }
  
  .carousel-control-next-icon {
    background-color: darkgray;
    position: absolute;
    right: 0em;
  }
  .formModalDatahub {
    left: 0em;
    bottom: 2em;
    position: relative;
  }
  .formModalOem {
    left: 0em;
    bottom: 20em;
    position: relative;
  }
  .formModalSales {
    left: 0em;
    top: -3em;
    position: relative;
  }
  .DisplayNoneMobile {
    display: none;
  }
  .formModalFleet {
    left: 0em;
    top: -1em;
    position: relative;
  }
  .custom-modal {
    margin-top: 50px;
  }

  .SolutionIcon:hover {
    color: #df1d23;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1204px) {
  .carousel-control-prev {
    position: absolute;
    left: -15em;
  
  }
  
  .carousel-control-next {
    position: absolute;
    right: -15em;
  }

  .carousel-control-prev-icon {
    background-color: darkgray;
    position: absolute;
    left: 15em;
  }
  
  .carousel-control-next-icon {
    background-color: darkgray;
    position: absolute;
    right: 15em;
  }
}
@media (max-width: 480px) {
  .ModalIcon {
    width: 100px;
    height: 50px;
  }
  .formModal {
    left: 0em;
    bottom: 0em;
    position: relative;
  }

  .formModalDatahub {
    left: 0em;
    bottom: 2em;
    position: relative;
  }
  .formModalOem {
    left: 0em;
    bottom: 20em;
    position: relative;
  }
  .formModalSales {
    left: 0em;
    top: -3em;
    position: relative;
  }
  .DisplayNoneMobile {
    display: none;
  }
  .formModalFleet {
    left: 0em;
    top: -1em;
    position: relative;
  }
  .custom-modal {
    margin-top: 50px;
  }

  .SolutionIcon:hover {
    color: #df1d23;
    cursor: pointer;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .ModalIcon {
    width: 100px;
    height: 50px;
  }
  .formModal {
    left: 0em;
    bottom: 0em;
    position: relative;
  }
  .formModalDatahub {
    left: 0em;
    bottom: 2em;
    position: relative;
  }
  .formModalOem {
    left: 0em;
    bottom: 20em;
    position: relative;
  }
  .formModalSales {
    left: 0em;
    top: -3em;
    position: relative;
  }
  .DisplayNoneMobile {
    display: none;
  }
  .formModalFleet {
    left: 0em;
    top: -1em;
    position: relative;
  }
  .custom-modal {
    margin-top: 50px;
  }

  .SolutionIcon:hover {
    color: #df1d23;
    cursor: pointer;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .ModalIcon {
    width: 100px;
    height: 50px;
  }
  .formModal {
    left: 0em;
    bottom: 0em;
    position: relative;
  }

  .formModalDatahub {
    left: 0em;
    bottom: 2em;
    position: relative;
  }
  .formModalOem {
    left: 0em;
    bottom: 20em;
    position: relative;
  }
  .formModalSales {
    left: 10em;
    top: -3em;
    position: relative;
  }
  .DisplayNoneMobile {
    display: none;
  }
  .formModalFleet {
    left: 0em;
    top: -1em;
    position: relative;
  }
  .custom-modal {
    margin-top: 50px;
  }

  .SolutionIcon:hover {
    color: #df1d23;
    cursor: pointer;
  }
}

.ModalIcons {
  margin-left: 13em;
}

.Spamfonts {
  font-size: 12px;
  margin-right: 7em;
}
.fw-600 a {
  text-decoration: none;
}

/* .carousel-control-prev,
.carousel-control-next {
  pointer-events: none;
} */
/* .carousel-control-prev-icon {
  background-color: darkgray;
  position: absolute;
  left: 15em;
}

.carousel-control-next-icon {
  background-color: darkgray;
  position: absolute;
  right: 15em;
} */

.Solution-title {
  text-align: left;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-left: 8px;
}

.Solution-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.Solution-title span {
  font-weight: 200;
  margin-right: 5px;
}

.Solution-title::after {
  content: " SOLUTION";
  font-weight: bold;
  margin-left: 5px;
}

.Solution-title:hover {
  color: #df1d23;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .carousel-control-prev {
    position: absolute;
    left: -15em;
    background-color: darkgray;
  }

  .carousel-control-next {
    position: absolute;
    background-color: darkgray;
    right: -15em;
  }

  .carousel-control-prev-icon {
    position: absolute;
    left: 15em;
  }

  .carousel-control-next-icon {
    position: absolute;
    right: 15em;
  }
}
