ul {
  list-style: none;
  cursor: pointer;
}
a {
  text-decoration: none;
}
button {
  outline: none;
  border: none;
}
input {
  outline: none;
  border: none;
}

menu .Link .spy {
  color: #df1d23;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 4;
  background-color: transparent;
  transition: 0.6s ease-in-out;
}
nav.active {
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.157);
  background-color: #605959;
}
nav ul {
  display: flex;
}
nav ul li {
  /* font-family: 'Bebas Neue'; */
  height: 40px;
  line-height: 43px;
  margin: 3px;
  padding: 0px 22px;
  display: flex;
  /* font-size: 1.2rem; */

  /* font-weight: 500; */
  color: #fbfbfb;
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}
nav ul li:hover {
  /* background-color: #bf2222; */
  color: #df1d23;
  /* box-shadow: 5px 10px 30px rgba(246, 243, 243, 0.336); */
  transition: all ease 0.2s;
}
nav .menu-btn,
.menu-icon {
  display: none;
}

@media (max-width: 1100px) {
  .menu-icon {
    display: block;
  }
  nav {
    justify-content: space-between;
    height: 75px;
    padding: 0px 30px;
  }
  .logo img {
    width: 120px;
  }
  .header-btns {
    margin: 0;
  }
  .pr-btns {
    margin: 0;
  }
  .menu {
    display: none;
    position: absolute;
    top: 65px;
    left: 0px;
    background-color: #635e5e;
    border-bottom: 4px solid #ff1414;
    width: 100%;
    padding: 0px;
    margin: 0px;
  }
  .menu li {
    width: 100%;
  }
  nav .menu li {
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 25px;
    border: 1px solid rgba(38, 38, 38, 0.03);
  }
  nav .menu-icon {
    cursor: pointer;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
  }
  nav .menu-icon .nav-icon {
    background-color: #fff;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 24px;
  }
  nav .menu-icon .nav-icon:before,
  nav .menu-icon .nav-icon:after {
    background: rgb(71, 67, 67);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all ease-out 0.2s;
    width: 100%;
  }
  nav .menu-icon .nav-icon:before {
    top: 6px;
    background-color: #fff;
  }
  nav .menu-icon .nav-icon:after {
    top: -6px;
    background-color: #fff;
  }
  nav .menu-btn:checked ~ .menu-icon .nav-icon {
    background: transparent;
  }
  nav .menu-btn:checked ~ .menu-icon .nav-icon:before {
    transform: rotate(-45deg);
    top: 0;
    background-color: #fff;
  }
  nav .menu-btn:checked ~ .menu-icon .nav-icon:after {
    transform: rotate(45deg);
    top: 0;
    background-color: #fff;
  }
  nav .menu-btn {
    display: none;
  }
  nav .menu-btn:checked ~ .menu {
    display: block;
  }
  
}
@media (max-width: 400px) {
  .a-box {
    width: 100%;
    height: 400px;
  }
}
