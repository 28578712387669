/* .Salesdiv {
    margin-top: 9em;
    left: 15em;
    position: relative;
} */
.solution-icon {
  height: 90px;
  padding-left: 20px;
  padding-right: 10px;
}
.title-solution {
  text-align: left;
  font-size: 25px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-left: 8px;
}
.title-solution .line {
  left: 0%;
  top: 0;
  transform: translateX(-50%);
  bottom: 0;
  width: 2px;
}

.AlertIcon {
  height: 100px;
  width: 100px;
  margin-left: 50px;
}

.TextStyle {
  margin-left: 20px;
}

.slogan-font {
  font-size: 18px;
}

@media only screen and (max-width: 720px) {
  .Salesdiv {
    margin-top: 9em;
    margin-left: 0em;
    position: relative;
  }
  .InputField {
    width: 60%;
  }
  .InputFields {
    width: 60%;
  }
  .p-absolut {
    position: absolute;
    left: 20%;
  }
  .solution-icon {
    position: relative;
    right: 5%;
  }
  .TextStyle {
    margin-left: 0px;
    width: 90%;
  }
}

@media (max-width: 480px) {
  .Salesdiv {
    margin-top: 9em;
    left: 1em;
    position: relative;
  }
  .InputField {
    width: 90%;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .Salesdiv {
    margin-top: 9em;
    left: 1em;
  }
  .InputField {
    width: 60%;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .Salesdiv {
    margin-top: 9em;
    left: 0em;
    position: relative;
  }
  .InputField {
    width: 60%;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .Salesdiv {
    margin-top: 9em;
    /* left: 5em; */
    position: relative;
  }
  .InputField {
    width: 60%;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .Salesdiv {
    /* margin-top: 9em;
    left:10em; 
        position: relative; */
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 9em;
  }
  .InputField {
    width: 75%;
  }
  .InputFields {
    width: 80%;
  }
  .solution-icon {
    position: relative;
    right: 5%;
    padding-left: 35px;
  }
  .p-absolut {
    position: absolute;
    left: 6%;
  }
}
