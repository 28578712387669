.contact-area .address i{
    height: 60px;
    width: 60px;
    line-height: 60px;
    display: inline-block;
}

.contact-form {
    border-radius: 3px;
}

.form-group{
    margin-bottom: 0;
}

.form-control{
    display: block;
    border: 0px;
    width: 100%;
    height: 45px;
    padding: 5px 0px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #333;
    text-indent: 20px;
    background-color: transparent;
    border: 1px solid #ddd;
    border-radius: 0px;
    box-shadow: none;
    margin-bottom: 30px;
    font-weight: 300;
}

.form-control:focus {
    outline: 0;
    box-shadow: none;
}

.has-error .form-control,
.has-error .form-control:focus{
    box-shadow: none;
    outline: none;
}

.contact-form input[type='submit']{
    padding: 10px 25px;
    border: 1px solid #df1d23;
    outline: 0;
    box-shadow: none;
}

.contact-form input[type='submit']:hover {
    background-color: transparent;
    border-color: #df1d23;
    outline: 0;
    box-shadow: none;
    cursor: pointer;
}

.g-recaptcha {
    position: relative;

    min-height: 10vh;
    transform: scale(1);
  }