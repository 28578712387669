.app-promo {
  position: absolute;
  right: 0px;
  left: 85%;
  bottom: -50px;
}

.area {
  background-color: blueviolet;
  position: absolute;
  z-index: 999;
}

.clickable-one {
  position: absolute;
  left: 83%;
  bottom: 100px;
  right: 0%;
  /* background-color: aqua; */
}

.home-promo-img {
  position: absolute;
  left: 0;
  bottom: 30px;
  height: 400px;
  width: auto;
}

.click-promo-img {
  position: absolute;
  height: 30px;
  width: 10%;
  bottom: 24%;
  left: 10px;
  cursor: pointer;
  /* background-color: aqua; */
}

@media only screen and (max-width: 1000px) {
  .app-promo {
    position: relative;
    right: 0%;
    left: 0%;
    top: 20px;
  }

  .Salesdiv {
    height: 100%;
  }
  .InputFields {
    width: 80%;
  }
  .mobile-promo {
    height: 50%;
  }
  .formModalSales {
    height: 50%;
  }
  .footer-bottom {
    margin-top: 5em;
  }

  .smart-app-footer-bottom {
    margin-top: 23em;
  }

  .clickable-one {
    position: relative;
    left: 3%;
    width: 40px;
    /* background-color: blueviolet; */
  }

  /*The Home screen*/
  .home-promo-img {
    position: absolute;
    left: 0;
    bottom: -60px;

    height: 300px;
    width: auto;
  }
  .click-promo-img {
    position: absolute;
    height: 30px;
    width: 30%;
    bottom: 5%;
    left: 10px;
    cursor: pointer;
    /* background-color: aqua; */
  }
}
