/* -------------------------------- 
  
  xpush 
  
  -------------------------------- */

/* special media for buttons */
@media (max-width: 350px) {
  .btn-1 {
    padding: 10px 18px;
  }
  .btn-2 {
    padding: 10px 18px;
  }
  .btn-3 {
    padding: 9px 17px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .welcome-area.creative-2 {
    -webkit-clip-path: polygon(50% 0, 100% 0, 100% 91%, 73% 100%, 0 91%, 0 0);
    clip-path: polygon(50% 0, 100% 0, 100% 91%, 73% 100%, 0 91%, 0 0);
  }

  .welcome-area.creative-3 {
    -webkit-clip-path: polygon(50% 0, 100% 0, 100% 91%, 50% 100%, 0 91%, 0 0);
    clip-path: polygon(50% 0, 100% 0, 100% 91%, 50% 100%, 0 91%, 0 0);
  }

  .welcome-area.creative-4 {
    -webkit-clip-path: polygon(
      100% 0,
      100% 98%,
      75% 93%,
      50% 98%,
      25% 93%,
      0 98%,
      0 0
    );
    clip-path: polygon(100% 0, 100% 98%, 75% 93%, 50% 98%, 25% 93%, 0 98%, 0 0);
  }
}

/* Medium devices (tablets, 768px and up)  */
@media (max-width: 767px) {
  .title-p {
    margin-bottom: 10px;
  
  }

  h1 {
    font-size: 42px;
    line-height: 42px;
  }
  h2 {
    font-size: 33px;
    line-height: 33px;
  }
  h3 {
    font-size: 25px;
    line-height: 26px;
  }
  h4 {
    font-size: 22px;
    line-height: 24px;
  }
  h5 {
    font-size: 18px;
    line-height: 21px;
  }
  h6 {
    font-size: 15px;
    line-height: 18px;
  }

  .navbar {
    padding: 15px 0;
  }

  .navbar-collapse {
    background-color: #fff;
    margin-top: 10px;
  }

  .navbar .navbar-nav .nav-link {
    color: #333;
    text-align: center;
  }

  .welcome-area .welcome-text p,
  .welcome-slider p,
  .welcome-video .welcome-text p,
  .welcome-particles .welcome-text p {
    margin: 0 10%;
  }

  .image-back-right,
  .image-back-left {
    position: relative;
    top: 0;
    left: 0;
    height: 400px;
  }

  .text-box {
    padding: 80px 30px;
  }

  .why-area .why-dots .dot:after {
    content: none;
  }

  .testimonials .single-review p {
    line-height: 1.5em;
    margin: 0 10%;
  }
}

/* Large devices (desktops, 992px and up)  */
@media (max-width: 991px) {
  /**** navbar-center-logo ****/
  .navbar-center-logo .navbar-brand {
    position: relative;
    left: 0;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  .navbar-center-logo .navbar-collapse {
    background-color: #fff;
    margin-top: 10px;
  }

  .navbar-center-logo .navbar-nav .nav-link {
    color: #333;
    text-align: center;
  }
}

/* Extra large devices (large desktops, 1200px and up)  */
@media (max-width: 1200px) {
}

@media (min-width: 1400px) {
  .container {
    max-width: 85%;
  }

  .title-p {
    margin: 0 61% 70px 0;
  }
}

/*----------------------------------------------------
					01-global Style
----------------------------------------------------*/
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  outline: none !important;
}

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

.fa {
  font-family: "FontAwesome";
  font-style: normal;
}

a,
a:hover {
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
  color: #fff;
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 50px;
  line-height: 50px;
}
h2 {
  font-size: 40px;
  line-height: 45px;
}
h3 {
  font-size: 30px;
  line-height: 34px;
}
h4 {
  font-size: 24px;
  line-height: 27px;
}
h5 {
  font-size: 21px;
  line-height: 25px;
}
h6 {
  font-size: 17px;
  line-height: 20px;
}

p {
  font-size: 15px;
  line-height: 24px;
  font-weight: 300;
  color: #666;
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: #333;
}

.title-h {
  text-align: left;
  font-size: 35px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-left: 8px;
}

.title-h .line {
  left: 0%;
  top: 0;
  transform: translateX(-50%);
  bottom: 0;
  width: 2px;
}

.title-p {
  text-align: left;
  /* margin: 0 55% 70px 0; */
  font-size: 15px;
  color: #666;
}

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.flex-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.img-responsive {
  width: auto;
  max-width: 100%;
  height: auto;
}

/*** position ***/
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.left-0 {
  left: 0;
}

.image-back-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.image-back-left {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.image-back-center {
  position: absolute;
  left: 33.333333%;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.text-box {
  padding: 80px;
}

/*** padding ***/
.sec-padding {
  padding: 80px 0;
}
.footer-padding {
  padding: 40px 0;
}
.no-padding {
  padding: 0 !important;
}
.p-5px {
  padding: 5px;
}
.p-10px {
  padding: 5px 10px;
}
.p-15px {
  padding: 15px;
}
.p-20px {
  padding: 20px;
}
.p-40px {
  padding: 40px;
}
.p-50px {
  padding: 50px;
}
.pt-30px {
  padding-top: 30px;
}
.pb-15px {
  padding-bottom: 15px;
}
.pr-30px {
  padding-right: 30px;
}
.pr-50px {
  padding-right: 50px;
}
.pl-3px {
  padding-left: 3px;
}
.pl-5px {
  padding-left: 5px;
}
.pl-30px {
  padding-left: 30px;
}
.pl-50px {
  padding-left: 50px;
}

/***** margin *****/

/* margin-top */
.mt-0px {
  margin-top: 0px;
}
.mt-5px {
  margin-top: 5px;
}
.mt-10px {
  margin-top: 10px;
}
.mt-15px {
  margin-top: 15px;
}
.mt-20px {
  margin-top: 20px;
}
.mt-25px {
  margin-top: 25px;
}
.mt-30px {
  margin-top: 30px;
}
.mt-40px {
  margin-top: 40px;
}

/* margin-bottom */
.mb-0px {
  margin-bottom: 0px !important;
}
.mb-5px {
  margin-bottom: 5px;
}
.mb-6px {
  margin-bottom: 6px;
}
.mb-7px {
  margin-bottom: 7px;
}
.mb-10px {
  margin-bottom: 10px;
}
.mb-15px {
  margin-bottom: 15px;
}
.mb-20px {
  margin-bottom: 20px;
}
.mb-25px {
  margin-bottom: 25px;
}
.mb-30px {
  margin-bottom: 30px;
}
.mb-35px {
  margin-bottom: 35px;
}
.mb-40px {
  margin-bottom: 40px;
}
.mb-50px {
  margin-bottom: 50px;
}

/* margin-right */
.mr-2px {
  margin-right: 2px;
}
.mr-5px {
  margin-right: 5px;
}
.mr-10px {
  margin-right: 10px;
}
.mr-15px {
  margin-right: 15px;
}

/* margin-left */
.ml-2px {
  margin-left: 2px;
}
.ml-5px {
  margin-left: 5px;
}
.ml-10px {
  margin-left: 10px;
}

/***** font-weight *****/
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}

/***** font-size *****/
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-25 {
  font-size: 25px;
}
.fs-30 {
  font-size: 30px;
}
.fs-35 {
  font-size: 35px;
}
.fs-40 {
  font-size: 40px;
}
.fs-45 {
  font-size: 45px;
}
.fs-50 {
  font-size: 50px;
}

.font-Poppins {
  font-family: "Poppins", sans-serif;
}

/***** colors *****/
.color-333 {
  color: #333;
}
.color-666 {
  color: #666;
}
.color-777 {
  color: #777;
}
.color-888 {
  color: #888;
}
.color-999 {
  color: #999;
}
.color-fff {
  color: #fff;
}
.color-eee {
  color: #eee;
}
.color-ddd {
  color: #ddd;
}
.color-ccc {
  color: #ccc;
}
.color-bbb {
  color: #bbb;
}
.color-aaa {
  color: #aaa;
}
.color-orange {
  color: #df1d23;
}
.color-orange-hvr:hover {
  color: #df1d23;
}
.color-fff-hvr:hover {
  color: #fff;
}
.color-gold {
  color: #ecb312;
}

/***** background-color *****/
.bg-fff {
  background-color: #fff;
}
.bg-f6f6f6 {
  background-color: #f6f6f6;
}
.bg-f5f5f5 {
  background-color: #f5f5f5;
}
.bg-f4f4f4 {
  background-color: #f4f4f4;
}
.bg-000 {
  background-color: #000;
}
.bg-111 {
  background-color: #111;
}
.bg-222 {
  background-color: #222;
}
.bg-333 {
  background-color: #333;
}
.bg-orange {
  background-color: #df1d23;
}
.bg-orange-hvr:hover {
  background-color: #df1d23;
}
.bg-333-hvr:hover {
  background-color: #333;
}
.bg-fff-hvr:hover {
  background-color: #fff;
}

/***** overlay *****/
.overlay-bg-10 {
  background-color: rgba(0, 0, 0, 0.1);
}
.overlay-bg-50 {
  background-color: rgba(0, 0, 0, 0.55);
}
.overlay-bg-60 {
  background-color: rgba(0, 0, 0, 0.6);
}
.overlay-bg-65 {
  background-color: rgba(0, 0, 0, 0.65);
}
.overlay-bg-70 {
  background-color: rgba(0, 0, 0, 0.7);
}
.overlay-bg-75 {
  background-color: rgba(0, 0, 0, 0.75);
}
.overlay-bg-80 {
  background-color: rgba(0, 0, 0, 0.8);
}
.overlay-bg-85 {
  background-color: rgba(0, 0, 0, 0.85);
}
.overlay-bg-90 {
  background-color: rgba(0, 0, 0, 0.9);
}

/***** transition *****/
.transition-2 {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.transition-3 {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.transition-4 {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.transition-5 {
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

/***** border-radius *****/
.radius-50 {
  border-radius: 50%;
}
.radius-5px {
  border-radius: 5px;
}
.radius-25px {
  border-radius: 25px;
}
.radius-50px {
  border-radius: 50px;
}

.o-hidden {
  overflow: hidden;
}
.f-right {
  float: right;
}
.f-left {
  float: left;
}
.width-100 {
  width: 100%;
}
.lh-25px {
  line-height: 25px;
}
.opacity-0 {
  opacity: 0;
}
.d-in-block {
  display: inline-block;
}
.z-index-1 {
  z-index: 1;
}
.z-index-3 {
  z-index: 3;
}

/***** buttons *****/
.main-btn {
  display: inline-block;
  text-decoration: none;
  border: none;
  border-radius: 30px;
  position: relative;
  font-size: 14px;
  word-spacing: 2px;
  font-weight: 400;
  transform: perspective(1px) translateX(0px);
  transition: all 0.2s ease 0s;
  overflow: hidden;
}

.main-btn:before {
  content: "";
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
  z-index: -1;
}

.main-btn.before-f6f6f6:before {
  background-color: #f6f6f6;
}

.main-btn.before-222:before {
  background-color: #222;
}

.main-btn.before-111:before {
  background-color: #111;
}

.main-btn:hover:before {
  transform: scaleX(1);
}

/* btn-1 */
.btn-1 {
  padding: 10px 26px;
  background-color: #df1d23;
  color: #fff;
}
.btn-1:hover {
  color: #333;
}

.btn-1:hover:before {
  transform: scaleX(1);
}

/* btn-2 */
.btn-2 {
  padding: 10px 26px;
  background-color: transparent;
  color: #fff;
}
.btn-2:hover {
  color: #333;
}

.btn-2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: -2;
  border-radius: 30px;
  border: 2px solid rgba(255, 255, 255, 0.7);
}

/* btn-3 */

/*----------------------------------------------------
                     02-load-wrapp
 ----------------------------------------------------*/
.load-wrapp {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.load-wrapp .wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.load-wrapp .wrap ul.dots-box {
  position: relative;
  width: 80px;
  height: 80px;
  list-style: none;
}

.load-wrapp .wrap ul.dots-box li.dot {
  width: 100%;
  height: 100%;
  border-radius: 52px;
  top: 0;
  left: 0;
  z-index: 99;
  text-indent: -9999px;
  display: block;
  position: absolute;
  border: none;
}
.load-wrapp .wrap ul.dots-box li.dot span {
  background: #df1d23;
  bottom: 0px;
  left: 50%;
  margin-left: -2px;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.load-wrapp .wrap ul.dots-box li.dot {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: orbit;
  animation-duration: 4s;
}

.load-wrapp .wrap ul.dots-box li:nth-child(2) {
  animation-delay: 0.2s;
}

.load-wrapp .wrap ul.dots-box li:nth-child(3) {
  animation-delay: 0.4s;
}

.load-wrapp .wrap ul.dots-box li:nth-child(4) {
  animation-delay: 0.6s;
}

.load-wrapp .wrap ul.dots-box li:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes orbit {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  5% {
    transform: rotate(90deg);
    opacity: 1;
  }
  45% {
    transform: rotate(270deg);
    opacity: 1;
  }
  55% {
    transform: rotate(540deg);
    opacity: 1;
  }
  75% {
    transform: rotate(630deg);
    opacity: 1;
  }
  80% {
    transform: rotate(720deg);
    opacity: 0;
  }
  100% {
    transform: rotate(720deg);
    opacity: 0;
  }
}
/*----------------------------------------------------
                     End load-wrapp
 ----------------------------------------------------*/

/*----------------------------------------------------
                     News Section
 ----------------------------------------------------*/
.News-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* z-index: 1; */
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: 2;  */
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay-parent {
  position: relative;
  margin-top: -6%;
  z-index: 3;
  padding-bottom: 10px;
}
.overlay-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 0px;
  z-index: 3;
  justify-content: center;
  box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.5);
  min-height: 30vh;
  padding-bottom: 10px;
}
.News-img {
  height: 60%;
  width: 60%;
}

.overlay-container .btn-link {
  position: absolute;
  bottom: 10px;
  left: 0px;
  right: 0px;
}

@media screen and (max-width: 600px) {
  .News-img {
    height: 100%;
    width: 100%;
  }
}
