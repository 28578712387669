.container {
  padding-right: 0px;
  padding-left: 0px;
}
.ImgService {
  width: 400px;
  height: 200px;
}
.blog-item {
  float: left;
  width: 24%;
  padding: 0 10px 10px;
  margin-bottom: 10px;
  
}
.row {
  margin: 0 -5px;
}
/*Carousel*/

.cards {
  display: flex;
  padding: 25px 0px;
  list-style: none;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  
}

.card {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  /* padding: 12px; */
  background: var(--white);
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
  /* scroll-snap-align: start; */
  /* transition: 3s; 
  animation: 15s autoplay2 infinite ease-in-out */
  transition: all 1s ease-in;
  scroll-behavior: smooth;
}

.paddin {
  padding: 12px;
  
}

.ImgConstruction {
  height: 100px;
  width: 100px;
  margin: auto;
  margin-top: 25px;
  
}
@keyframes autoplay2 {
  0% {left: 100%}
  4% {left: 0%}
  33.33% {left: 0%}
  37.33% {left: -100%}
  100% {left: -100%}
}

#card > *:nth-child(1) {animation-delay: 0s}
#card > *:nth-child(2) {animation-delay: 4s}
#card > *:nth-child(3) {animation-delay: 8s}

.card:not(:last-child) {
  margin-right: 10px;
}

.card:hover {
  color: var(--white);
  background: var(--red);
}

.card .card-title {
  font-size: 20px;
}

.card .card-content {
  margin: 20px 0;
  max-width: 85%;
}

.card .card-link-wrapper {
  margin-top: auto;
}

.card .card-link {
  display: inline-block;
  text-decoration: none;
  color: white;
  background: var(--red);
  padding: 6px 12px;
  border-radius: 8px;
  transition: background 0.2s;
}

.card:hover .card-link {
  background: var(--darkred);
}

.cards::-webkit-scrollbar {
  height: 12px;
}

.cards::-webkit-scrollbar-thumb,
.cards::-webkit-scrollbar-track {
  border-radius: 92px;
}

.cards::-webkit-scrollbar-thumb {
  background: var(--darkred);
}

.cards::-webkit-scrollbar-track {
  background: var(--thumb);
}

@media (min-width: 500px) {
  .card {
    flex-basis: calc(50% - 10px);
  }

  .card:not(:last-child) {
    margin-right: 20px;
  }
}

@media (min-width: 700px) {
  .card {
    flex-basis: calc(calc(100% / 3) - 20px);
  }

  .card:not(:last-child) {
    margin-right: 30px;
  }
}

@media (min-width: 1100px) {
  .card {
    flex-basis: calc(25% - 30px);
  }

  .card:not(:last-child) {
    margin-right: 40px;
  }
}

.SolutionIcon:hover {
  color: #df1d23;
}



.item-slider-container {
  background-color: var(--sp-background-color);
  box-shadow: 0px 0px 16px -8px rgb(0 0 0 / 68%);
}

.item-slider-container .item-title {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding-bottom: 15px;
}

.item-slider {
  padding: 0px 5px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.item-slider .item-container {
  display: inline-flex;
  justify-content: flex-start;
  overflow-x: scroll;
  padding: 0px 5px;
}

.item-slider .item-container span {
  margin: 0px 10px;
}

.item-slider .item-container::-webkit-scrollbar {
  display: none;
}

.item-slider .left-arrow-left {
  position: absolute;
  left: 15px;
  cursor: pointer;
  z-index: 1;
}

.item-slider .right-arrow-right {
  position: absolute;
  right: 0px;
  cursor: pointer;
  z-index: 1;
}

.right-arrow-right>svg,
.left-arrow-left>svg {
  color: #c3c3c1;
}

.right-arrow-right svg:hover,
.left-arrow-left svg:hover {
  color: #5b5b5b;
  border-radius: 50%;
}

/* Card item styles */
.item-container .card {
  border: unset;
}

.item-container .card-title {
  font-size: 14px;
  font-weight: normal;
}

.item-container .card span {
  padding: 0;
  margin: 0;
}

.item-container .card .mrp-price {
  font-size: 14px;
  font-weight: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.item-container .card .save-price {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--sp-exam-button-color);
}

.item-container .card .actual-price {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}